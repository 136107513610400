import Avatar from '../avatar';
import { LinkButton } from '../common/button';
import { getInitials } from '../../helpers';
import './auditorItem.scss';

const AuditorItem = ({ auditor:{ img, nameAuditor, jobPosition, tel, mail } }) => {
    return (
        <li className="auditor-list__card">
            <article className="auditor-card">
                <Avatar src={img} alt={`Аватар ${nameAuditor}`} text={getInitials(nameAuditor)} />
                <div className="auditor-card__info">
                    <h3 className="auditor-card__name">{nameAuditor}</h3>
                    <p className="auditor-card__position">{jobPosition}</p>
                </div>

                <div className="auditor-card__btn-wrapper">
                    <LinkButton 
                        href={`tel:${tel}`}
                        classes="btn-icon-circle btn-sm auditor-card__btn"
                        title={tel}
                        leftIcon="icon-phone"
                    />
                    <LinkButton 
                        href={`mailto:${mail}`}
                        classes="btn-icon-circle btn-sm auditor-card__btn"
                        title={mail}
                        leftIcon="icon-email"
                    />
                </div>
            </article>
        </li>
    )
}

export default AuditorItem;