import React, { useState } from 'react';
import { loginUser } from '../api/auth';
import styles from './loginPage.module.css';
import { useAppDispatch } from '../hooks/redux';
import { login } from '../store/authSlice';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const dispatch = useAppDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await loginUser(username, password);
            console.log("User logged in successfully");
            console.log("Response:", response);
            if (response.access && response.refresh) {
                // Сохранение токенов в localStorage
                localStorage.setItem('access_token', response.access);
                localStorage.setItem('refresh_token', response.refresh);

                // Диспатчим действие login при успешном входе
                dispatch(login());

                console.log("Access Token:", response.access);
                console.log("Refresh Token:", response.refresh);
            } else {
                throw new Error('Invalid login response');
            }
        } catch (err) {
            setError("Failed to log in: " + (err.response ? err.response.data : err.message));
            console.log(error);
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Вход</h1>
            <form className={styles.form} onSubmit={handleLogin}>
                <label className={styles.label} htmlFor="username">Имя пользователя</label>
                <input 
                    className={styles.input} 
                    type="text" 
                    id="username" 
                    name="username" 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <label className={styles.label} htmlFor="password">Пароль</label>
                <input 
                    className={styles.input} 
                    type="password" 
                    id="password" 
                    name="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <button className={styles.button} type="submit">Войти</button>
            </form>
            {error && <p className={styles.error}>{error}</p>}
            <div className={styles.footer}>
                <p>Вы - новый пользователь?</p>
                <button className={styles.linkButton}>Зарегистрироваться</button>
            </div>
            <div className={styles.footer}>
                <p>Забыли пароль?</p>
                <button className={styles.linkButton}>Восстановить пароль</button>
            </div>
        </div>
    );
}

export default LoginPage;
