import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuditsByYear, fetchAuditYears, createAudit, removeAudit } from '../store/auditSlice';
import styles from './planPage.module.css';

const PlanPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector((state) => state.audits.audits);
    const years = useSelector((state) => state.audits.years);
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);

    const currentYear = new Date().getFullYear();
    const [newAudit, setNewAudit] = useState({
        name: '',
        start_date: '',
        end_date: '',
        year: currentYear,
    });
    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        dispatch(fetchAuditYears());
    }, [dispatch]);

    useEffect(() => {
        if (years.length > 0) {
            setSelectedYear(Math.max(...years, currentYear));
        }
    }, [years]);

    useEffect(() => {
        dispatch(fetchAuditsByYear(selectedYear));
    }, [selectedYear, dispatch]);

    const handleAddAudit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(createAudit(newAudit)).unwrap();
            setNewAudit({ name: '', start_date: '', end_date: '', year: selectedYear });
            dispatch(fetchAuditsByYear(selectedYear)); // Перезагружаем аудиты после добавления нового
        } catch (error) {
            console.error('Error adding audit:', error);
        }
    };

    const handleDeleteAudit = async (auditId) => {
        try {
            await dispatch(removeAudit(auditId)).unwrap();
            dispatch(fetchAuditsByYear(selectedYear)); // Перезагружаем аудиты после удаления
        } catch (error) {
            console.error('Error deleting audit:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAudit({ ...newAudit, [name]: value });
    };

    const handleYearChange = (e) => {
        setSelectedYear(parseInt(e.target.value, 10));
    };

    const handleNewAuditYearChange = (e) => {
        const year = e.target.value;
        setNewAudit({ ...newAudit, year: year });
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Plan Page</h1>
            <div className={styles.yearSelector}>
                <label htmlFor="year">Select Year: </label>
                <select id="year" value={selectedYear} onChange={handleYearChange} className={styles.select}>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <form onSubmit={handleAddAudit} className={styles.form}>
                <input
                    type="text"
                    name="name"
                    placeholder="Audit Name"
                    value={newAudit.name}
                    onChange={handleInputChange}
                    required
                    className={styles.input}
                />
                <input
                    type="date"
                    name="start_date"
                    value={newAudit.start_date}
                    onChange={handleInputChange}
                    required
                    className={styles.input}
                />
                <input
                    type="date"
                    name="end_date"
                    value={newAudit.end_date}
                    onChange={handleInputChange}
                    required
                    className={styles.input}
                />
                <input
                    type="number"
                    name="year"
                    value={newAudit.year}
                    onChange={handleNewAuditYearChange}
                    required
                    className={styles.input}
                />
                <button type="submit" className={styles.button}>Add Audit</button>
            </form>
            {auditStatus === 'loading' && <div>Loading...</div>}
            {auditStatus === 'failed' && <div className={styles.error}>{error}</div>}
            <ul className={styles.auditList}>
                {audits.map((audit) => (
                    <li key={audit.id} className={styles.auditItem}>
                        {audit.name} ({audit.start_date} - {audit.end_date})
                        <button onClick={() => handleDeleteAudit(audit.id)} className={styles.deleteButton}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PlanPage;
