const mockMainChartsLineData = {
    labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль'],
    datasets: [
        {
            label: 'Выручка',
            data: [5000, 2000, 10000, 20000, 15000, 30000, 23000],
            borderColor: '#3B82F6',
            backgroundColor: '#3B82F6',
        },
        {
            label: 'Расходы',
            data: [500, 200, 1000, 2000, 1500, 3000, 2300],
            borderColor: '#22C55E',
            backgroundColor: '#22C55E',
        },
        {
            label: 'Штрафы',
            data: [100, 400, 200, 5000, 3000, 900, 1000],
            borderColor: '#C42B1C',
            backgroundColor: '#C42B1C'
        },
        {
            label: 'Закупки',
            data: [1000, 1000, 1500, 10000, 900, 2000, 15000],
            borderColor: '#E09B33',
            backgroundColor: '#E09B33',
        }
    ],
};
const mockMainChartsBarData = {
    labels: ["Income"],
    datasets: [
        {
            label: 'Income',
            data: [43.29],
            total: "$161,466.24",
            borderColor: '#3B82F6',
            backgroundColor: '#3B82F6',
        },
        {
            label: 'Income',
            data: [36.16],
            total: "$56,411.33",
            borderColor: '#22C55E',
            backgroundColor: '#22C55E',
        },
        {
            label: 'Income',
            data: [40.22],
            total: "$81,981.22",
            borderColor: '#C42B1C',
            backgroundColor: '#C42B1C'
        },
        {
            label: 'Income',
            data: [25.53],
            total: "$12,432.51",
            borderColor: '#E09B33',
            backgroundColor: '#E09B33',
        }
    ]
}

export { mockMainChartsLineData, mockMainChartsBarData };