import InfographicsItem from '../infographicsItem';
import "./infographicsList.scss";

const InfographicsList = ({ title, dataArray }) => {
    return (
        <section className="infographics-list">
            <h2 className="sr-only">{title}</h2>
            {
                dataArray.map((item) => (
                    <InfographicsItem key={item.heading}
                        heading={item.heading}
                        sum={item.sum}
                        currency={item.currency}
                        href={item.href}
                        trendDirection={item.trendDirection}
                        iconChart={item.iconChart}
                        trendPercent={item.trendPercent}
                        iconCurrency={item.iconCurrency}
                    />
                ))
            }
        </section>

    )
}

export default InfographicsList;