import { Button } from '../common/button';
import "./tasksActions.scss";

const TasksActions = () => {
    return (
        <div className="tasks-actions">
            <Button 
                type="button"
                classes="btn-icon"
                title="Меню поручений"
                leftIcon="icon-menu"
            />
            <Button 
                type="button"
                classes="btn-primary btn-sm"
                title="Создать поручение"
                leftIcon="icon-email"
                rightIcon="icon-chevron-down"
            />
            <div className='tasks-actions__center-part'>
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Удалить"
                    leftIcon="icon-delete _color-gray"
                />
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Архив"
                    leftIcon="icon-archive _color-green"
                />
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Важное"
                    leftIcon="icon-shield-error _color-red"
                />
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Копировать"
                    leftIcon="icon-broom _color-gray"
                />
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Перенести"
                    leftIcon="icon-folder-arrow-right _color-blue"
                    rightIcon="icon-chevron-down"
                />
                <div className='tasks-actions__delimiter' aria-hidden="true"></div>
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Ответить"
                    leftIcon="icon-arrow-reply-all _color-violet"
                    rightIcon="icon-chevron-down"
                />
                <div className='tasks-actions__delimiter' aria-hidden="true"></div>
                <Button 
                    type="button"
                    classes="btn-sm"
                    title="Отметить все"
                    leftIcon="icon-mail-read-multiple _color-gray"
                />
            </div>
            <div className='tasks-actions__right-part'>
                <Button 
                    type="button"
                    classes="btn-icon _two-icons btn-sm"
                    title="Теги"
                    leftIcon="icon-tag"
                    rightIcon="icon-chevron-down _color-gray"
                />
                <Button 
                    type="button"
                    classes="btn-icon _two-icons btn-sm"
                    title="Важность"
                    leftIcon="icon-flag _color-red"
                    rightIcon="icon-chevron-down"
                />
                <Button 
                    type="button"
                    classes="btn-icon _two-icons btn-sm"
                    title="Закрепить"
                    leftIcon="icon-pin _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon _two-icons btn-sm"
                    title="Дата"
                    leftIcon="icon-clock _color-gray"
                    rightIcon="icon-chevron-down"
                />
                <div className='tasks-actions__delimiter' aria-hidden="true"></div>
                <Button 
                    type="button"
                    classes="btn-icon btn-sm"
                    title="Отменить"
                    leftIcon="icon-arrow-undo"
                />
                <div className='tasks-actions__delimiter' aria-hidden="true"></div>
                <Button 
                    type="button"
                    classes="btn-icon btn-sm"
                    title="Меню поручений"
                    leftIcon="icon-more"
                />
            </div>
        </div>
    )
}

export default TasksActions;