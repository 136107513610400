import React, { useState } from 'react';
import { InputText } from '../common/form-control';
import { Button } from '../common/button';
import AuditorItem from "../auditorItem";
import "./auditorList.scss";
import DraggableAuditor from '../draggableAuditor/draggableAuditor';

const AuditorList = ({ title, auditorList }) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const filteredAuditors = auditorList.filter(auditor =>
        `${auditor.nameAuditor}`.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <section className="auditor-list">
            <h2 className='sr-only'>{title}</h2>
            <div className="auditor-list__search-control search-control">
                <InputText
                    id="search"
                    label="Поиск"
                    placeholder="Поиск"
                    isScreenReaderOnly
                    inputClasses="auditor-list__input"
                    value={searchText}
                    onChange={handleSearchChange}
                />
                <Button
                    type="button"
                    classes="btn-icon"
                    title="Поиск"
                    leftIcon="icon-search"
                />
            </div>
            <ul className="auditor-list__list">
                {
                    filteredAuditors.map((card, index) => (
                        <DraggableAuditor key={index} auditor={card}>
                            <AuditorItem key={index} auditor={card} />
                        </DraggableAuditor>
                    ))
                }
            </ul>
        </section>
    );
};

export default AuditorList;
