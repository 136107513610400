import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Button } from '../common/button';
import { InputCheckbox } from '../common/form-control';
import "./mainCharts.scss";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const optionsLine = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                padding: 24
            }
        }
    }
};

const optionsBar = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        }
    },
    scales: {
        y: {
            display: false
        }
    }
};

const MainCharts = ({ title, lineChartData, barChartData, isShowDownloadBtn = false }) => {
    const [uploadStatus, setUploadStatus] = useState(null);
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Here you can handle the file upload logic if needed
            // For now, we just simulate success/failure
            setUploadStatus('success');
            setFileName(file.name);
        }
    };

    return (
        <section className="main-charts">
            <h2 className='sr-only'>{title}</h2>
            {isShowDownloadBtn && (
                <div className="main-charts__btns">
                    <label className="btn-primary btn-xs main-charts__upload-label">
                        Загрузить данные
                        <input 
                            type="file" 
                            onChange={handleFileChange} 
                            style={{ display: 'none' }}
                        />
                    </label>
                    <Button 
                        type="button"
                        classes="btn-primary-outline btn-xs"
                        title='"Выбросы"'
                    />
                    <Button 
                        type="button"
                        classes="btn-primary-outline btn-xs"
                        title="Выборка"
                    />
                </div>
            )}
            {uploadStatus && (
                <div className={`upload-status ${uploadStatus}`}>
                    {uploadStatus === 'success' ? (
                        <span>Файл {fileName} успешно загружен</span>
                    ) : (
                        <span>Ошибка при загрузке файла {fileName}</span>
                    )}
                </div>
            )}
            <div className="main-charts__wrap">
                <div className="main-charts__chart _line">
                    <div className='main-charts__chart-header'>
                        <h2 className='main-charts__chart-title'>Ключевой график</h2>
                        <Button 
                            type="button"
                            classes="btn-icon btn-xs"
                            title="Меню"
                            leftIcon='icon-more-ver'
                        />
                    </div>
                    <div className='main-charts__chart-controls'>
                        <Button 
                            type="button"
                            classes="btn-text btn-xs"
                            title="Месяцы"
                            rightIcon='icon-chevron-down'
                        />
                        <div className='main-charts__chart-checkbox-list form-group-line'>
                            <InputCheckbox 
                                id="chart-proceeds"
                                label="Выручка"
                                defaultChecked
                            />
                            <InputCheckbox 
                                id="chart-expenses"
                                label="Расходы"
                                defaultChecked
                            />
                            <InputCheckbox 
                                id="chart-fines"
                                label="Штрафы"
                            />
                            <InputCheckbox 
                                id="chart-purchase"
                                label="Закупки"
                            />
                        </div>
                    </div>
                    <div className='main-charts__chart-wrap'>
                        <Line options={optionsLine} data={lineChartData} />
                    </div>
                </div>
                <div className="main-charts__chart _bar">
                    <div className='main-charts__chart-header'>
                        <h2 className='main-charts__chart-title'>График 2</h2>
                        <Button 
                            type="button"
                            classes="btn-icon btn-xs"
                            title="Меню"
                            leftIcon='icon-more-ver'
                        />
                    </div>
                    <div className='main-charts__chart-wrap'>
                        <Bar options={optionsBar} data={barChartData} />
                    </div>
                    <div className='main-charts__chart-bar-info bar-info'>
                        {
                            barChartData.datasets.map((item, index) => (
                                <div key={index} className='bar-info__item'>
                                    <div className='bar-info__item-left-part'>
                                        <div 
                                            style={{ backgroundColor: item.backgroundColor}}
                                            className='bar-info__circle'
                                            aria-hidden="true"></div>
                                        <span>{item.label}</span>
                                        <span 
                                            style={{ color: item.backgroundColor}} 
                                            className='bar-info__icon icon icon-chart-trending-up' 
                                            aria-hidden="true">
                                        </span>
                                        <span style={{ color: item.backgroundColor}}>{item.data}%</span>
                                    </div>
                                    <span>{item.total}</span>
                                </div>
                                
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainCharts;
