const mockReportComments = {
    disadvantages: [
        {
            title: 'Недополучена выручка',
            totalAmount: '10 млн',
            description: 'Описание недостатка'
        },
        {
            title: 'Излишние расходы',
            totalAmount: '10 млн',
            description: 'Описание недостатка'
        }
    ],
    recommendations: [
        {
            textRecomendation: 'Пожалуйста начните работать',
            userName: 'Иванов А.А.',
            data: '31.02.2024'
        },
        {
            textRecomendation: 'Напишите регламент . . .',
            userName: 'Петров Б.Б.',
            data: '30.02.2024'
        }
    ]
}

export default mockReportComments;