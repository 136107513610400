const mockTasksListMenu = {
    title: 'Поручения',
    radioName: 'tasks-list-menu',
    menuItems: [
        {
            id: 'tasks-list-my',
            tag: 'my',
            title: 'Мои',
            iconName: 'icon-inbox',
            count: '2',
            isChecked: true
        },
        {
            id: 'tasks-list-from-me',
            tag: 'fromMe',
            title: 'От меня',
            iconName: 'icon-send',
            count: '',
            isChecked: false
        },
        {
            id: 'tasks-list-draft',
            tag: 'draft',
            title: 'Черновики',
            iconName: 'icon-draft',
            count: '5',
            isChecked: false
        }
    ]
};
const mockTasksCompletedMenu = {
    title: "Завершенные",
    radioName: 'tasks-completed-list-menu',
    menuItems: [
        {
            id: 'tasks-completed-list-my',
            tag: 'my',
            title: 'Мои',
            iconName: 'icon-archive',
            count: '146',
            isChecked: true
        },
        {
            id: 'tasks-completed-list-from-me',
            tag: 'fromMe',
            title: 'От меня',
            iconName: 'icon-send',
            count: '',
            isChecked: false
        }
    ]
};

export { mockTasksListMenu, mockTasksCompletedMenu };