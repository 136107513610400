import './header.scss';
import png from '../../resources/images/avatar@1x.png';
import Avatar from '../avatar';
import { Button } from '../common/button';
import { InputText } from '../common/form-control';

const Header = () => {
    return (
        <header className="header">
            <div className="header__left-part">
                <Button 
                    type="button"
                    classes="btn-icon-circle"
                    title="Меню"
                    leftIcon="icon-menu"
                />
                <div className="search-control">
                    <InputText 
                        id="search"
                        label="Поиск"
                        placeholder="Поиск"
                        isScreenReaderOnly
                    />
                    <Button 
                        type="button"
                        classes="btn-icon"
                        title="Поиск"
                        leftIcon="icon-search"
                    />
                </div>
            </div>
            <div className="header__right-part">
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Избранное"
                    leftIcon="icon-heart _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon _with-indicator"
                    title="Уведомления"
                    leftIcon="icon-notification _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Сообщения"
                    leftIcon="icon-chat _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Настройки"
                    leftIcon="icon-settings _color-blue"
                />
                <Avatar src={png} alt="Аватар Иванова Ивана"/>
            </div>
        </header>
    )
}

export default Header;