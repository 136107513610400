import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuditsByYear, updateAuditStatus, fetchAuditStatuses, removeAudit } from '../store/auditSlice';
import styles from './coordinationPage.module.css';

const CoordinationPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector(state => state.audits.currentYearAudits);
    const auditStatuses = useSelector(state => state.audits.auditStatuses);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        dispatch(fetchAuditsByYear(currentYear));
        dispatch(fetchAuditStatuses());
    }, [dispatch]);

    const handleStatusChange = (auditId, newStatus) => {
        dispatch(updateAuditStatus({ auditId, status: newStatus }));
    };

    const handleAuditRemove = async (auditId) => {
        await dispatch(removeAudit(auditId));
        const currentYear = new Date().getFullYear();
        dispatch(fetchAuditsByYear(currentYear));
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'approved':
                return styles.approved;
            case 'rejected':
                return styles.rejected;
            case 'needs_revision':
                return styles.needsRevision;
            case 'no_info':
            default:
                return styles.noInfo;
        }
    };

    return (
        <div className={styles.coordinationPage}>
            <h1>Согласование</h1>
            <table className={styles.coordinationTable}>
                <thead>
                    <tr>
                        <th>Название аудита</th>
                        <th>Дата начала</th>
                        <th>Дата окончания</th>
                        <th>Статус</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {audits.map((audit) => (
                        <tr key={audit.id} className={getStatusClass(audit.status_text)}>
                            <td>{audit.name}</td>
                            <td>{new Date(audit.start_date).toLocaleDateString()}</td>
                            <td>{new Date(audit.end_date).toLocaleDateString()}</td>
                            <td>{audit.status_text}</td>
                            <td>
                                <button
                                    className={`${styles.statusButton} ${styles.approveButton}`}
                                    onClick={() => handleStatusChange(audit.id, 'approved')}
                                >
                                    Согласовать
                                </button>
                                <button
                                    className={`${styles.statusButton} ${styles.rejectButton}`}
                                    onClick={() => handleAuditRemove(audit.id)}
                                >
                                    Отклонить
                                </button>
                                <button
                                    className={`${styles.statusButton} ${styles.revisionButton}`}
                                    onClick={() => handleStatusChange(audit.id, 'needs_revision')}
                                >
                                    Доработать
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CoordinationPage;
