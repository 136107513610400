// api/utils.js

import { refreshAccessToken } from './auth';

export const BASE_URL_PLANNING = "http://femida.pro:8001";

export const cacheHeaders = {
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
    'Pragma': 'no-cache',
};

export const getHeaders = (isAuth = false) => {
    const headers = {
        ...cacheHeaders,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
    if (isAuth) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }
    }
    return headers;
};

export const handleResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json();
        console.error('Request failed:', errorData);
        throw new Error(`Request failed: ${response.statusText}`);
    }
    return response.json();
};

export const fetchWithAuth = async (url, options = {}) => {
    const headers = getHeaders(true);
    console.log('Sending request to:', url);
    console.log('Request options:', { ...options, headers });

    let response = await fetch(url, { ...options, headers });

    console.log('Response status:', response.status);
    
    if (response.status === 401) {
        const newAccessToken = await refreshAccessToken();
        headers['Authorization'] = `Bearer ${newAccessToken}`;
        response = await fetch(url, { ...options, headers });
    }

    return handleResponse(response);
};
