import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  AuditPage,
  AnalyticsPage,
  ReportPage,
  DocumentsPage,
  ResourсesPage,
  TasksListPage,
  TasksNewPage,
  TasksCompletedPage,
  RegistrationPage,
  LoginPage,
  PlanPage,
  CoordinationPage,
} from "../../pages";
import Header from "../header/header";
import Menu from "../menu/menu";
import ProtectedRoute from "../protectedRoute/protectedRoute";
import LogoutButton from "../logoutButton/logoutButton";

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Header />
        <Menu />
        <LogoutButton /> {/* Добавляем кнопку выхода */}
        <main>
          <Routes>
            <Route
              path="/login"
              element={
                <ProtectedRoute anonymous>
                  <LoginPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AuditPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/audit"
              element={
                <ProtectedRoute>
                  <AuditPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/analytics"
              element={
                <ProtectedRoute>
                  <AnalyticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute>
                  <ReportPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/documents"
              element={
                <ProtectedRoute>
                  <DocumentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/resourses"
              element={
                <ProtectedRoute>
                  <ResourсesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks-list"
              element={
                <ProtectedRoute>
                  <TasksListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks-new"
              element={
                <ProtectedRoute>
                  <TasksNewPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks-completed"
              element={
                <ProtectedRoute>
                  <TasksCompletedPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plan"
              element={
                <ProtectedRoute>
                  <PlanPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/coordination"
              element={
                <ProtectedRoute>
                  <CoordinationPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
      </Router>
    </DndProvider>
  );
}

export default App;
