import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import './avatar.scss';

const Avatar = ({ src, alt, text, classes, onClick, auditorName }) => {
    return (
        <div 
            className={`avatar ${classes}`} 
            onClick={onClick} 
            data-tooltip-id={`avatar-tooltip-${alt}`}
            data-tooltip-content={auditorName}
        >
            {src ? <img src={src} alt={alt} /> : <span>{text}</span>}
            <Tooltip id={`avatar-tooltip-${alt}`} place="top" effect="solid" />
        </div>
    );
};

Avatar.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    text: PropTypes.string,
    classes: PropTypes.string,
    onClick: PropTypes.func,
    auditorName: PropTypes.string,
};

export default Avatar;
