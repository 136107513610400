import React from 'react';

const AnalyticsPage = () => {
  return (
    <iframe
      src="http://femida.pro:6875"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Bookstack"
    />
  );
};

export default AnalyticsPage;
