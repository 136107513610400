const mockInfographicsData = [
    {
        heading: 'Выручка',
        sum: 999,
        currency: 'млн руб',
        href: '/report',
        iconCurrency: 'icon-dollar',
        trendPercent: '+16.24'
    },
    {
        heading: 'Расходы',
        sum: 800,
        currency: 'млн руб',
        href: '/report',
        iconCurrency: 'icon-dollar',
        trendPercent: '+16.24'
    },
    {
        heading: 'Штрафы',
        sum: 1,
        currency: 'млн руб',
        href: '/report',
        iconCurrency: 'icon-dollar',
        trendPercent: '+16.24',
    },
    {
        heading: 'Закупки',
        sum: 200,
        currency: 'млн руб',
        href: '/report',
        iconCurrency: 'icon-dollar',
        trendPercent: '-5.66'
    }
]

export default mockInfographicsData;