// src/components/logoutButton/LogoutButton.js
import React from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { logout } from '../../store/authSlice';

const LogoutButton = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default LogoutButton;
