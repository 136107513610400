import InfographicsList from '../components/infographicsList';
import MainCharts from '../components/mainCharts';
import ReportComments from '../components/reportComments';
import mockInfographicsData from '../components/infographicsList/mockInfographics';
import mockReportComments from '../components/reportComments/mockReportComments';
import { mockMainChartsLineData, mockMainChartsBarData } from '../components/mainCharts/mockMainCharts';

const ReportPage = () => {
    return (
        <>
            <InfographicsList title="Инфографика" dataArray={mockInfographicsData} />
            <MainCharts 
                title="Графики" 
                lineChartData={mockMainChartsLineData} 
                barChartData={mockMainChartsBarData} 
            />
            <ReportComments title="Комментарии" comments={mockReportComments}/>
        </>
    )
}

export default ReportPage;