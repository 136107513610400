import Avatar from '../avatar';
import { Button } from '../common/button';
import { getInitials } from '../../helpers';
import './teamItem.scss';

const TeamItem = ({ avatarUrl, name, jobPosition }) => {
    return (
        <li className='team-item'>
            <article>
                <div className='team-item__main-part'>
                    <div className='team-item__info'>
                        <Avatar src={avatarUrl} alt={name} text={getInitials(name)} classes="_large" />
                        <div>
                            <span className='team-item__name'>{name}</span>
                            <span className='team-item__job-position'>{jobPosition}</span>
                        </div>
                    </div>
                    <Button 
                        type="button"
                        classes="btn-icon btn-xs"
                        title="Меню"
                        leftIcon="icon-more-ver"
                    />
                </div>
                <div className='team-item__btns'>
                    <Button 
                        type="button"
                        classes="btn-primary btn-xs"
                        title="Звонок"
                    />
                    <Button 
                        type="button"
                        classes="btn-secondary btn-xs"
                        title="Поручение"
                    />
                </div>
            </article>
        </li>
    )
}

export default TeamItem;