// src/components/resourcesGant/resourcesGant.js

import React from "react";
import Avatar from "../avatar";
import { getInitials } from "../../helpers";
import "./resourcesGant.scss";
import DroppableAudit from "../droppableAudit/droppableAudit";
import DroppableCurator from "../droppableCurator/droppableCurator";

const ResourcesGant = ({
  title,
  resourcesData,
  onRemoveAuditor,
  onRemoveCurator,
  onAddCurator,
}) => {
  const months = resourcesData?.months || [];

  const getPosition = (dataStart, dataEnd) => {
    const startPosition = months.indexOf(dataStart);
    const endPosition = months.indexOf(dataEnd);
    const duration = endPosition - startPosition + 1;
    const marginLeft = `${(100 / months.length) * startPosition}%`;
    const width = `${(100 / months.length) * duration}%`;

    return { marginLeft, width };
  };

  const widthColumnPercent = `${100 / months.length}%`;

  return (
    <section className="resources-gant">
      <h2 className="section-title">{title}</h2>
      <div className="resources-gant__chart">
        <div className="resources-gant__timeline">
          {months.map((month, index) => (
            <span key={index} className="resources-gant__timeline-item">
              {month}
            </span>
          ))}
        </div>
        <div
          className="resources-gant__list"
          style={{ backgroundSize: `${widthColumnPercent} 100%` }}
        >
          {resourcesData.data.map((audit) => (
            <div
              key={`audit-${audit.id}`} // Добавлен уникальный ключ для каждого элемента аудита
              style={{
                backgroundColor: audit.bgColor,
                marginLeft: getPosition(audit.dataStart, audit.dataEnd)
                  .marginLeft,
                width: getPosition(audit.dataStart, audit.dataEnd).width,
              }}
              className="resources-gant__item"
            >
              <DroppableAudit key={`droppable-${audit.id}`} audit={audit}>
                <span className="resources-gant__item-title">
                  {audit.title}
                </span>
              </DroppableAudit>
              <div className="resources-gant__item-auditors">
                {!!audit.auditors &&
                  audit.auditors.map(
                    ({ id, auditorName, auditorAvatarUrl }) => (
                      <Avatar
                        key={`auditor-${id}`} // Уникальный ключ для каждого элемента Avatar
                        src={auditorAvatarUrl}
                        alt={auditorName}
                        text={getInitials(auditorName || "")}
                        classes="_sm"
                        auditorName={auditorName}
                        onClick={() => onRemoveAuditor(audit.id, id)}
                      />
                    )
                  )}
              </div>
              <div className="resources-gant__curator">
                {audit.curator ? (
                  <Avatar
                    key={`curator-avatar-${audit.curator.id}`} // Уникальный ключ для каждого куратора
                    src={audit.curator.avatarUrl}
                    alt={audit.curator.name}
                    text={getInitials(audit.curator.name || "")}
                    classes="_lg"
                    auditorName={audit.curator.name}
                    onClick={() => onRemoveCurator(audit.id)}
                  />
                ) : (
                  <DroppableCurator
                    key={`curator-${audit.id}`} // Уникальный ключ для DroppableCurator
                    auditId={audit.id}
                    onAddCurator={onAddCurator}
                  >
                    <div className="curator-placeholder">+</div>
                  </DroppableCurator>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResourcesGant;
