const mockPerformerListData = [
    {
        value: 'mikhail-martirosov',
        title: 'Михаил Мартиросов'
    },{
        value: 'vladimir-medvedev',
        title: 'Владимир Медведев'
    }
];

const mockProjectListData = [
    {
        value: 'audit-1',
        title: 'Аудит 1'
    },{
        value: 'audit-2',
        title: 'Аудит 2'
    },{
        value: 'audit-3',
        title: 'Аудит 3'
    }
];

const mockStringencyListData = [
    {
        value: 'high',
        title: 'Высокая'
    },{
        value: 'middle',
        title: 'Средняя'
    },{
        value: 'low',
        title: 'Низкая'
    }
];

export { 
    mockPerformerListData,
    mockProjectListData,
    mockStringencyListData
};