import { useState } from 'react';
import TasksActions from '../components/tasksActions';
import TasksMenu from '../components/tasksMenu';
import TasksList from '../components/tasksList';
import { mockTasksListData, mockTasksFromMeListData, mockTasksDraftListData } from '../components/tasksList/mockTasks';
import { mockTasksListMenu } from '../components/tasksMenu/mockTasksMenu';

const TasksListPage = () => {
    let mockTasksList = mockTasksListData;
    const [list, setList] = useState(mockTasksList);
    const getTasksList = (tag) => {
        switch(tag) {
            case 'my':
                setList(mockTasksListData);
                break;
            case 'fromMe':
                setList(mockTasksFromMeListData);
                break;
            case 'draft':
                setList(mockTasksDraftListData);
                break;
            default:
                return null
        }
    }
    return (
        <div className='spaces-y-8'>
            <TasksActions />
            <div className='d-flex'>
                <TasksMenu menuList={mockTasksListMenu} getTasksList={getTasksList}/>
                <TasksList tasksListData={list} />   
            </div>
        </div>
    )
}

export default TasksListPage;