import TaskAddForm from '../components/taskAddForm';
import TasksList from '../components/tasksList';
import { mockPerformerListData, mockProjectListData, mockStringencyListData } from '../components/taskAddForm/mockSelectList';
import { mockTasksNewListData } from '../components/tasksList/mockTasks';

const TasksNewPage = () => {
    return (
        <div className='d-flex'>
            <TaskAddForm 
                performerList={mockPerformerListData}
                projectList={mockProjectListData}
                stringencyList={mockStringencyListData}
            />
            <TasksList tasksListData={mockTasksNewListData} isNew />   
        </div>
    )
}

export default TasksNewPage;