import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers } from '../api/user';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await getUsers();
    return response;
});

const transformUserData = (users) => {
    return users.map(user => ({
        id: user.id,
        img: '',
        nameAuditor: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
        jobPosition: user.position || 'Не указано',
        tel: user.phone || 'Не указан',
        mail: user.email,
        auditorAvatarUrl: '',
    }));
};

const userSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        transformedUsers: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
                state.transformedUsers = transformUserData(action.payload);
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default userSlice.reducer;
