import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableAuditor = ({ auditor, children }) => {
    const [, drag] = useDrag({
        type: 'AUDITOR',
        item: { id: auditor.id, nameAuditor: auditor.nameAuditor, img: auditor.img }
    });

    return (
        <div ref={drag}>
            {children}
        </div>
    );
};

export default DraggableAuditor;
