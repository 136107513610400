// api/auth.js

import { BASE_URL_PLANNING, getHeaders, handleResponse } from './utils';

export const loginUser = async (username, password) => {
    const url = `${BASE_URL_PLANNING}/api/login/`;
    const data = { username, password };

    const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data)
    });

    const userData = await handleResponse(response);

    if (userData.access && userData.refresh) {
        localStorage.setItem('access_token', userData.access);
        localStorage.setItem('refresh_token', userData.refresh);
    }

    return userData;
};

export const refreshAccessToken = async () => {
    const url = `${BASE_URL_PLANNING}/api/token/refresh/`;
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ refresh: refreshToken })
    });

    const data = await handleResponse(response);
    localStorage.setItem('access_token', data.access);
    return data.access;
};
