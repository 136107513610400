import TeamList from '../components/teamList';
import ProjectSchedule from '../components/projectSchedule';
import AuditProgram from '../components/auditProgram';
import mockTeamListData from '../components/teamList/mockTeamList';
import mockProjectScheduleData from '../components/projectSchedule/mockProjectSchedule';
import mockAuditProgram from '../components/auditProgram/mockAuditProgram';

const AuditPage = () => {
    return (
        <>
            <h1 className='main-title'>Аудит управления ИТ-мощностями</h1>
            <AuditProgram programTitle="Программа аудита" program={mockAuditProgram} />
            <TeamList title="Команда" list={mockTeamListData} />
            <ProjectSchedule title="График проекта" shedule={mockProjectScheduleData} />
        </>
    )
}

export default AuditPage;