import { InputText, InputTextarea, Select } from '../common/form-control';
import { Button } from '../common/button';
import './taskAddForm.scss';

const TaskAddForm = ({ performerList, projectList, stringencyList }) => {
    return (
        <div className='task-add-form'>
            <h2 className='task-add-form__title'>Новое поручение</h2>
            <form action="/" className='task-add-form__form'>
                <Select 
                    id="task-performer"
                    label="Исполнитель"
                    selectName="performer"
                    selectList={performerList}
                    isRequired
                    selectClasses="input-sm"
                />
                <Select 
                    id="task-project"
                    label="Проект"
                    selectName="project"
                    selectList={projectList}
                    isRequired
                    selectClasses="input-sm"
                />
                <InputText 
                    id="task-name"
                    label="Название"
                    isRequired
                    inputClasses="input-sm"
                />
                <InputTextarea 
                    id="task-description"
                    label="Описание"
                    rowCount="5"
                    inputClasses="input-sm"
                />
                <Select 
                    id="task-stringency"
                    label="Срочность"
                    selectName="stringency"
                    selectList={stringencyList}
                    isRequired
                    selectClasses="input-sm"
                />
                <InputText 
                    id="task-deadline"
                    label="Крайний срок"
                    isRequired
                    inputClasses="input-sm"
                />
                <Button 
                    type="submit"
                    classes="btn-primary btn-sm mt-24"
                    title="Создать поручение"
                />
            </form>
        </div>
    )
}

export default TaskAddForm;