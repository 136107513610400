import { getInitials } from '../../helpers';
import "./tasksItem.scss";

const TasksItem = ({ 
    userAvatarUrl, 
    userName, 
    projectName, 
    taskName, 
    taskDescription, 
    status, stringency, 
    isNotReaded, 
    dataReceive, 
    dataDeadline, 
    dataComplete, 
    isCompleted,
    isNew
}) => {
    return (
        <div className={`tasks-item ${isNotReaded ? '_not-readed' : ''} ${isCompleted ? '_completed' : ''} ${isNew ? '_new' : ''}`}>
            { userAvatarUrl ? (
                <div className="tasks-item__avatar">
                    <img src={userAvatarUrl} alt={userName} />
                </div>
            ):(
                <div className="tasks-item__avatar-default">
                    <span>{getInitials(userName)}</span>
                </div>
            )}
            <span className="tasks-item__item _user-name">{userName}</span>
            <span className="tasks-item__item">{projectName}</span>
            <span className="tasks-item__item _task-title">
                <span className="tasks-item__item-task-name">{taskName}</span>
                {taskDescription}
            </span>
            {
                !isNew && !isCompleted && (
                    <>
                        { !!status && <span className="tasks-item__item">{status}</span> }
                        { !!stringency && <span className="tasks-item__item">{stringency}</span> }
                    </>
                )
            }
            <span className="tasks-item__item">{dataReceive}</span>
            {
                !isNew && (
                    isCompleted ? (
                        !!dataComplete && <span className="tasks-item__item">{dataComplete}</span>
                    ):(
                        !!dataDeadline && <span className="tasks-item__item">{dataDeadline}</span>
                    )
                )
            }
        </div>
    )
}

export default TasksItem;