// src/store/auditSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers } from "../api/user";
import {
  addAudit,
  deleteAudit,
  getAuditsByYear,
  getAuditYears,
  addAuditorToAuditAPI,
  removeAuditorFromAuditAPI,
  getAuditStatusesAPI,
  updateAuditStatusAPI,
  addCuratorToAuditAPI,
  removeCuratorFromAuditAPI,
} from "../api/audit";

export const fetchAuditsByYear = createAsyncThunk(
  "audits/fetchAuditsByYear",
  async (year) => {
    const response = await getAuditsByYear(year);
    return response;
  }
);

export const fetchAuditYears = createAsyncThunk(
  "audits/fetchAuditYears",
  async () => {
    const response = await getAuditYears();
    return response;
  }
);

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await getUsers();
  return response;
});

export const fetchAuditStatuses = createAsyncThunk(
  "audits/fetchAuditStatuses",
  async () => {
    const response = await getAuditStatusesAPI();
    return response;
  }
);

export const createAudit = createAsyncThunk(
  "audits/createAudit",
  async (auditData) => {
    const response = await addAudit(auditData);
    return response;
  }
);

export const removeAudit = createAsyncThunk(
  "audits/removeAudit",
  async (auditId) => {
    await deleteAudit(auditId);
    return auditId;
  }
);

export const updateAuditStatus = createAsyncThunk(
  "audits/updateAuditStatus",
  async ({ auditId, status }) => {
    const response = await updateAuditStatusAPI(auditId, status);
    return response;
  }
);

export const addAuditorToAuditThunk = createAsyncThunk(
  "audits/addAuditorToAudit",
  async ({ auditId, auditor }, { getState }) => {
    const state = getState();
    const audit = state.audits.audits.find((a) => a.id === auditId);
    
    if (audit.curator === auditor.id) {
      await removeCuratorFromAuditAPI(auditId);
    }

    const response = await addAuditorToAuditAPI(auditId, auditor.id);
    return { auditId, auditor, resource: response };
  }
);

export const addCuratorToAuditThunk = createAsyncThunk(
  "audits/addCuratorToAudit",
  async ({ auditId, auditor }, { getState }) => {
    const state = getState();
    const audit = state.audits.audits.find((a) => a.id === auditId);
    
    if (audit.resources.some((resource) => resource.user === auditor.id)) {
      await removeAuditorFromAuditAPI(auditId, auditor.id);
    }

    const response = await addCuratorToAuditAPI(auditId, auditor.id);
    return { auditId, curator: auditor, resource: response };
  }
);

export const removeCuratorFromAuditThunk = createAsyncThunk(
  "audits/removeCuratorFromAudit",
  async ({ auditId }) => {
    const response = await removeCuratorFromAuditAPI(auditId);
    return { auditId };
  }
);

export const removeAuditorFromAuditThunk = createAsyncThunk(
  "audits/removeAuditorFromAudit",
  async ({ auditId, auditorId }) => {
    await removeAuditorFromAuditAPI(auditId, auditorId);
    return { auditId, auditorId };
  }
);

const transformAuditData = (audits, users) => {
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return months[date.getMonth()];
  };

  const colors = ["#E09B33", "#EA3D32", "#E05A33", "#6A45A2", "#56BD66"];
  let colorIndex = 0;

  return audits.map((audit) => {
    const color = colors[colorIndex % colors.length];
    colorIndex++;

    const curator = users.find((user) => user.id === audit.curator) || null;

    return {
      id: audit.id,
      title: audit.name,
      dataStart: getMonthName(audit.start_date),
      dataEnd: getMonthName(audit.end_date),
      auditors: audit.resources.map((resource) => {
        const user = users.find((u) => u.id === resource.user);
        return {
          id: resource.user,
          auditorName: user
            ? `${user.first_name} ${user.last_name}`
            : "Unknown",
          auditorAvatarUrl: user ? user.avatarUrl : "",
        };
      }),
      curator: curator
        ? {
            id: curator.id,
            name: `${curator.first_name} ${curator.last_name}`,
            avatarUrl: curator.avatarUrl,
          }
        : null,
      bgColor: color,
      status_text: audit.status_text,
    };
  });
};

const auditSlice = createSlice({
  name: "audits",
  initialState: {
    audits: [],
    currentYearAudits: [],
    transformedData: [],
    years: [],
    users: [],
    auditStatuses: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditsByYear.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAuditsByYear.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.audits = action.payload;
        const currentYear = new Date().getFullYear();
        state.currentYearAudits = state.audits.filter(
          (audit) => audit.year === currentYear
        );
        state.transformedData = transformAuditData(
          state.currentYearAudits,
          state.users
        );
      })
      .addCase(fetchAuditsByYear.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAuditYears.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAuditYears.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.years = action.payload;
      })
      .addCase(fetchAuditYears.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
        state.transformedData = transformAuditData(
          state.currentYearAudits,
          state.users
        );
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAuditStatuses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAuditStatuses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.auditStatuses = action.payload;
      })
      .addCase(fetchAuditStatuses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAuditStatus.fulfilled, (state, action) => {
        const updatedAudit = action.payload;
        const index = state.audits.findIndex(
          (audit) => audit.id === updatedAudit.id
        );
        if (index !== -1) {
          state.audits[index] = updatedAudit;
          state.currentYearAudits = state.audits.filter(
            (audit) => audit.year === new Date().getFullYear()
          );
          state.transformedData = transformAuditData(
            state.currentYearAudits,
            state.users
          );
        }
      })
      .addCase(addAuditorToAuditThunk.fulfilled, (state, action) => {
        const { auditId, auditor, resource } = action.payload;
        const audit = state.audits.find((a) => a.id === auditId);
        if (audit) {
          if (audit.curator === auditor.id) {
            audit.curator = null;
          }
          audit.resources.push(resource);
          state.currentYearAudits = state.audits.filter(
            (audit) => audit.year === new Date().getFullYear()
          );
          state.transformedData = transformAuditData(
            state.currentYearAudits,
            state.users
          );
        }
      })
      .addCase(addCuratorToAuditThunk.fulfilled, (state, action) => {
        const { auditId, curator } = action.payload;
        const audit = state.audits.find((a) => a.id === auditId);
        if (audit) {
          audit.curator = curator.id;
          audit.resources = audit.resources.filter(
            (resource) => resource.user !== curator.id
          );
          state.currentYearAudits = state.audits.filter(
            (audit) => audit.year === new Date().getFullYear()
          );
          state.transformedData = transformAuditData(
            state.currentYearAudits,
            state.users
          );
        }
      })
      .addCase(removeCuratorFromAuditThunk.fulfilled, (state, action) => {
        const { auditId } = action.payload;
        const audit = state.audits.find((a) => a.id === auditId);
        if (audit) {
          audit.curator = null;
          state.currentYearAudits = state.audits.filter(
            (audit) => audit.year === new Date().getFullYear()
          );
          state.transformedData = transformAuditData(
            state.currentYearAudits,
            state.users
          );
        }
      })
      .addCase(removeAuditorFromAuditThunk.fulfilled, (state, action) => {
        const { auditId, auditorId } = action.payload;
        const audit = state.audits.find((a) => a.id === auditId);
        if (audit) {
          audit.resources = audit.resources.filter(
            (resource) => resource.user !== auditorId
          );
          state.currentYearAudits = state.audits.filter(
            (audit) => audit.year === new Date().getFullYear()
          );
          state.transformedData = transformAuditData(
            state.currentYearAudits,
            state.users
          );
        }
      });
  },
});

export default auditSlice.reducer;
