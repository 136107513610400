import { Button } from '../common/button';
import './auditProgram.scss';

const ProgramList = ({ listData }) => (
    <div className='audit-program__items-container'>
        {
            listData.map(({ title, bgColor, programList }, index) => (
                <div key={index} className='audit-program__item-wrap'>
                    <div className={`audit-program__item _bg-${bgColor}`}>
                        <Button 
                            type="button"
                            classes="btn-icon btn-xs audit-program__btn"
                            title="Меню"
                            leftIcon='icon-more'
                        />
                        <span className='audit-program__item-title'>{title}</span>
                    </div>
                    {
                        !!programList && <ProgramList listData={programList}/>
                    }
                </div>
            ))
        }
    </div>
);

const AuditProgram = ({ programTitle, program: { title, bgColor, programList } }) => {
    return (
        <section>
            <h2 className="section-title">{programTitle}</h2>
            <div className='audit-program'>
                <div className={`audit-program__item _bg-${bgColor}`}>
                    <Button 
                        type="button"
                        classes="btn-icon btn-xs audit-program__btn"
                        title="Меню"
                        leftIcon='icon-more'
                    />
                    <span className='audit-program__item-title'>{title}</span>
                </div>
                {
                    !!programList && <ProgramList listData={programList}/>
                }
            </div>
        </section>
    )
}

export default AuditProgram;