import "./form-control.scss";

const InputText = ({
  id,
  label,
  placeholder,
  isRequired = false,
  inputClasses,
  isScreenReaderOnly = false,
  value,
  onChange,
}) => {
  return (
    <div className="form-control">
      <label className={isScreenReaderOnly ? "sr-only" : ""} htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        required={isRequired}
        className={!!inputClasses ? inputClasses : ""}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const InputTextarea = ({
  id,
  label,
  placeholder,
  rowCount,
  isRequired = false,
  inputClasses,
  isScreenReaderOnly = false,
}) => {
  return (
    <div className="form-control">
      <label className={isScreenReaderOnly ? "sr-only" : ""} htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        placeholder={placeholder}
        rows={rowCount}
        required={isRequired}
        className={!!inputClasses ? inputClasses : ""}
      />
    </div>
  );
};

const Select = ({
  id,
  label,
  placeholder,
  selectName,
  selectList,
  isRequired = false,
  selectClasses,
  isScreenReaderOnly = false,
}) => {
  return (
    <div
      className={`form-control _select-control ${
        !!selectClasses ? selectClasses : ""
      }`}
    >
      <label className={isScreenReaderOnly ? "sr-only" : ""} htmlFor={id}>
        {label}
      </label>
      <select name={selectName} id={id} required={isRequired}>
        {!!placeholder && (
          <option value="" selected>
            {placeholder}
          </option>
        )}
        {selectList.map(({ value, title }) => (
          <option key={value} value={value}>
            {title}
          </option>
        ))}
      </select>
    </div>
  );
};

const InputCheckbox = ({
  id,
  label,
  defaultChecked = false,
  isRequired = false,
  inputClasses,
  isScreenReaderOnly = false,
}) => (
  <div className={`form-control ${!!inputClasses ? inputClasses : ""}`}>
    <input
      id={id}
      type="checkbox"
      required={isRequired}
      defaultChecked={defaultChecked}
    />
    <label className={isScreenReaderOnly ? "sr-only" : ""} htmlFor={id}>
      {label}
    </label>
  </div>
);

export { InputText, InputTextarea, Select, InputCheckbox };
