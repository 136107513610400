const mockTeamListData = [
    {
        avatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
        name: 'Анастасия Смирнова',
        jobPosition: 'Куратор'
    },
    {
        avatarUrl: '',
        name: 'Юрий Горюнов',
        jobPosition: 'Руководитель проекта'
    },
    {
        avatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
        name: 'Владимир Румянцев',
        jobPosition: 'Аналитик данных'
    },
    {
        avatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
        name: 'Анастасия Чернявская',
        jobPosition: 'Аудитор'
    }
];

export default mockTeamListData;