import { useState } from 'react';
import TasksActions from '../components/tasksActions';
import TasksMenu from '../components/tasksMenu';
import TasksList from '../components/tasksList';
import { mockTasksCompletedListData, mockTasksCompletedFromMeListData } from '../components/tasksList/mockTasks';
import { mockTasksCompletedMenu } from '../components/tasksMenu/mockTasksMenu';

const TasksCompletedPage = () => {
    let mockTasksList = mockTasksCompletedListData;
    const [list, setList] = useState(mockTasksList);
    const getTasksList = (tag) => {
        switch(tag) {
            case 'my':
                setList(mockTasksCompletedListData);
                break;
            case 'fromMe':
                setList(mockTasksCompletedFromMeListData);
                break;
            default:
                return null
        }
    }
    return (
        <div className='spaces-y-8'>
            <TasksActions />
            <div className='d-flex'>
                <TasksMenu menuList={mockTasksCompletedMenu} getTasksList={getTasksList}/>
                <TasksList tasksListData={list} isCompleted />   
            </div>
        </div>
    )
}

export default TasksCompletedPage;