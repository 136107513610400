// src/api/audit.js

import { BASE_URL_PLANNING, fetchWithAuth, getHeaders } from './utils';

export const addAudit = async (auditData) => {
    const url = `${BASE_URL_PLANNING}/api/audits/create/`;
    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(auditData)
    });
};

export const deleteAudit = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/${auditId}/delete/`;
    return fetchWithAuth(url, { 
        method: 'DELETE',
        headers: getHeaders(true)
    });
};

export const getAllAudits = async () => {
    const url = `${BASE_URL_PLANNING}/api/all-audits/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const getAuditsByYear = async (year) => {
    const url = `${BASE_URL_PLANNING}/api/audits/year/${year}/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const getAuditYears = async () => {
    const url = `${BASE_URL_PLANNING}/api/audits/years/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const addAuditorToAuditAPI = async (auditId, auditorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/add-auditor/`;
    const data = { audit_id: auditId, auditor_id: auditorId };

    return fetchWithAuth(url, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};

export const removeAuditorFromAuditAPI = async (auditId, auditorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/remove-auditor/`;
    const data = { audit_id: auditId, auditor_id: auditorId };

    console.log('DELETE Request URL:', url);
    console.log('DELETE Request Data:', data);

    return fetchWithAuth(url, {
        method: 'DELETE',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};

export const addCuratorToAuditAPI = async (auditId, curatorId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/add-curator/`;
    const data = { audit_id: auditId, curator_id: curatorId };
  
    return fetchWithAuth(url, {
      method: 'POST',
      headers: getHeaders(true),
      body: JSON.stringify(data)
    });
  };

export const removeCuratorFromAuditAPI = async (auditId) => {
    const url = `${BASE_URL_PLANNING}/api/audits/remove-curator/`;
    const data = { audit_id: auditId };
  
    return fetchWithAuth(url, {
      method: 'POST',
      headers: getHeaders(true),
      body: JSON.stringify(data)
    });
  };

export const getAuditStatusesAPI = async () => {
    const url = `${BASE_URL_PLANNING}/api/audit-statuses/`;
    return fetchWithAuth(url, { 
        method: 'GET',
        headers: getHeaders(true)
    });
};

export const updateAuditStatusAPI = async (auditId, status) => {
    const url = `${BASE_URL_PLANNING}/api/audits/${auditId}/update-status/`;
    const data = { status };

    return fetchWithAuth(url, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(data)
    });
};
