const mockAuditProgram = {
    title: 'Аудит ИТ-мощностей',
    bgColor: 'green',
    programList: [
        {
            title: 'Утилизация',
            bgColor: 'blue'
        },
        {
            title: 'Мониториг',
            bgColor: 'blue'
        },
        {
            title: 'Покрытие',
            bgColor: 'blue'
        },
        {
            title: 'Учёт',
            bgColor: 'red'
        },
        {
            title: 'Закупки',
            bgColor: 'red',
            programList: [
                {
                    title: 'Серверы',
                    bgColor: 'red'
                },
                {
                    title: 'ПО',
                    bgColor: 'red'
                }
            ]
        }
    ]
};

export default mockAuditProgram;