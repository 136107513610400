import { Button } from '../common/button';
import { InputText } from '../common/form-control';
import "./reportComments.scss";

const ReportComments = ({ title, comments: { disadvantages, recommendations } }) => {
    const renderTable = (commentList) => {
        const table = commentList.map((item, index) => (
            <div key={index} className="comments__table-row">
                <div className="comments__table-cell">{Object.values(item)[0]}</div>
                <div className="comments__table-cell">{Object.values(item)[1]}</div>
                <div className="comments__table-cell">{Object.values(item)[2]}</div>
            </div>
        ))

        return table;
    }
    return (
        <section className="comments">
            <h2 className="sr-only">{title}</h2>
            <div className="comments__table">
                <div className="comments__table-header">
                    <div className="comments__table-title">Недостатки</div>
                    <div className="comments__table-control">
                        <Button 
                            type="button"
                            classes="btn-sm"
                            title="Сортировка"
                            rightIcon="icon-chevron-down"
                        />
                        <Button 
                            type="button"
                            classes="btn-icon comments__table-btn"
                            title="Показать больше опций"
                            leftIcon="icon-more-ver"
                        />
                    </div>
                </div>
                {
                    renderTable(disadvantages)
                }
            </div>
            <div className="comments__table">
                <div className="comments__table-header">
                    <div className="comments__table-title">Рекомендации</div>
                    <div className="comments__table-control">
                        <div className="search-control">
                            <InputText 
                                id="search"
                                label="Поиск"
                                placeholder="Поиск"
                                isScreenReaderOnly
                                inputClasses="input-sm"
                            />
                            <Button 
                                type="button"
                                classes="btn-icon btn-sm"
                                title="Поиск"
                                leftIcon="icon-search"
                            />
                        </div>
                        <Button 
                            type="button"
                            classes="btn btn-icon comments__table-btn"
                            title="Показать больше опций"
                            leftIcon="icon-more-ver"
                        />
                    </div>
                </div>
                {
                    renderTable(recommendations)
                }
            </div>
        </section>
    )
}

export default ReportComments;