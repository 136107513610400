import React from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { addCuratorToAuditThunk } from '../../store/auditSlice';
import styles from './droppableCurator.module.css';

const DroppableCurator = ({ auditId, children }) => {
    const dispatch = useDispatch();

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'AUDITOR',
        drop: (item) => {
            dispatch(addCuratorToAuditThunk({ auditId, auditor: item }));
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div ref={drop} className={styles.droppableCurator}>
            {children}
            {isOver && canDrop && <div className={styles.overlay}>Drop here!</div>}
        </div>
    );
};

export default DroppableCurator;
