import React, { useState, useEffect } from 'react';
import MainCharts from '../components/mainCharts';
import DocumentComments from '../components/documentComments';
import mockDocumentComments from '../components/documentComments/mockDocumentComments';
import { mockMainChartsLineData, mockMainChartsBarData } from '../components/mainCharts/mockMainCharts';

const DocumentsPage = () => {
    const [iframeUpdate, setIframeUpdate] = useState(0);

    useEffect(() => {
        // Обработчик для событий из iframe
        const handleIframeMessage = (event) => {
            // Проверяем тип сообщения, чтобы убедиться, что оно пришло из нужного iframe
            if (event.data.type === 'UPDATE_IFRAME') {
                setIframeUpdate(prev => prev + 1);  // Обновляем состояние для перерендера
            }
        };

        // Добавляем обработчик событий
        window.addEventListener('message', handleIframeMessage);

        // Очищаем обработчик при размонтировании
        return () => window.removeEventListener('message', handleIframeMessage);
    }, []);

    return (
        <>
            <MainCharts 
                title="Графики" 
                lineChartData={mockMainChartsLineData} 
                barChartData={mockMainChartsBarData}
                isShowDownloadBtn
            />
            <iframe
              key={iframeUpdate}  // ключ для перерендера при изменении
              src="http://femida.pro:4000/audit/?menu=false"
              style={{ width: '100%', height: '100vh', border: 'none' }}
              title="Wagtail Analytics Page"
            />
        </>
    )
}

export default DocumentsPage;
