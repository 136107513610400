const mockProjectScheduleData = [
    {
        stageName: 'Планирование',
        stageData: '30.03',
        bgColor: 'orange'
    },
    {
        stageName: 'Полевые работы',
        stageData: '30.04',
        bgColor: 'blue'
    },
    {
        stageName: 'Согласование отчёта',
        stageData: '30.05',
        bgColor: 'green'
    }
];

export default mockProjectScheduleData;