import './projectSchedule.scss';

const ProjectSchedule = ({ title, shedule }) => {
    return (
        <section>
            <h2 className='section-title'>{title}</h2>
            <ul className='project-schedule'>
                {
                    shedule.map(({ stageName, stageData, bgColor }, index) => (
                        <li key={index} className={`project-schedule__item _bg-${bgColor}`}>
                            {stageName}
                            <span className='project-schedule__data'>{stageData}</span>
                        </li>
                    ))
                }
            </ul>
        </section>
    )
}

export default ProjectSchedule;