import { Link } from 'react-router-dom';
import './infographicsItem.scss';

const InfographicsItem = ({
    heading,
    sum,
    currency,
    href,
    iconCurrency,
    trendPercent = false
}) => {

    const trendDirection = Math.sign(trendPercent) > 0 ?  "up" : "down";

    return (
        <article className="infographics-item">
            <dl className="infographics-item__data">
                <dt className="infographics-item__heading">{heading}</dt>
                <dd className="infographics-item__sum-total">
                    <span>{sum}</span> 
                    <span>{currency}</span>
                </dd>
            </dl>

            <Link to={href} className="infographics-item__link">Детали</Link>

            {
                !!trendPercent && <p className={`infographics-item__trend infographics-item__trend_${trendDirection}`}>
                    <span className={`infographics-item__trend-icon icon icon-chart-trending-${trendDirection}`} aria-hidden="true"></span>
                    <span className="infographics-item__trend-percent">{trendPercent}&nbsp;&#37;</span>
                </p>
            }

            <span className={`infographics-item__currency icon ${iconCurrency}`} aria-hidden="true"></span>
        </article>
    )
}

export default InfographicsItem;