const mockTasksListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Владимир Медведев',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            },
        ]
    },
    {
        day: 'Вчера',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Михаил Марков',
                projectName: 'Аудит 1',
                taskName: 'Работать',
                taskDescription: 'Работать с 9 до 7',
                status: 'Нужно сделать',
                stringency: 'Низкая',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Иванов',
                projectName: 'Аудит 1',
                taskName: 'Не работать',
                taskDescription: 'Больше отдыхать',
                status: 'Нужно сделать',
                stringency: 'Низкая',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Петр Петров',
                projectName: 'Аудит 1',
                taskName: 'Закупка',
                taskDescription: 'Согласовать закупку',
                status: 'Нужно сделать',
                stringency: 'Низкая',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Отчёт',
                taskDescription: 'Согласовать отчёт',
                status: 'Нужно сделать',
                stringency: 'Низкая',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            }
        ]
    },
    {
        day: 'Раньше',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: true,
                dataReceive: '19.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '10.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '10.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '08.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Иванов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '05.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '01.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Иванов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '05.04 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'Проверка',
                stringency: 'Средняя',
                isNotReaded: false,
                dataReceive: '01.04 10:00',
                dataDeadline: '05.07 12:00'
            }
        ]
    }
];

const mockTasksFromMeListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataDeadline: '05.07 12:00'
            }
        ]
    },
    {
        day: 'Раньше',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая',
                isNotReaded: true,
                dataReceive: '19.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая',
                isNotReaded: false,
                dataReceive: '10.05 10:00',
                dataDeadline: '05.07 12:00'
            },
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Низкая',
                isNotReaded: false,
                dataReceive: '01.05 10:00',
                dataDeadline: '05.07 12:00'
            }
        ]
    }
];

const mockTasksDraftListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                status: 'В работе',
                stringency: 'Высокая'
            }
        ]
    }
];

const mockTasksCompletedListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Владимир Медведев',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            },
        ]
    },
    {
        day: 'Вчера',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Михаил Марков',
                projectName: 'Аудит 1',
                taskName: 'Работать',
                taskDescription: 'Работать с 9 до 7',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Иванов',
                projectName: 'Аудит 1',
                taskName: 'Не работать',
                taskDescription: 'Больше отдыхать',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Петр Петров',
                projectName: 'Аудит 1',
                taskName: 'Закупка',
                taskDescription: 'Согласовать закупку',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Отчёт',
                taskDescription: 'Согласовать отчёт',
                isNotReaded: false,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            }
        ]
    },
    {
        day: 'Раньше',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '19.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '10.05 10:00',
                dataComplete: '05.07 12:00'
            }
        ]
    }
];

const mockTasksCompletedFromMeListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '21.05 10:00',
                dataComplete: '05.07 12:00'
            }
        ]
    },
    {
        day: 'Раньше',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '19.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '10.05 10:00',
                dataComplete: '05.07 12:00'
            },
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '01.05 10:00',
                dataComplete: '05.07 12:00'
            }
        ]
    }
];

const mockTasksNewListData = [
    {
        day: 'Сегодня',
        tasksList: [
            {
                userAvatarUrl: 'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                userName: 'Михаил Мартиросов',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '21.05 10:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Владимир Медведев',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '21.05 10:00'
            },
        ]
    },
    {
        day: 'Вчера',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Михаил Марков',
                projectName: 'Аудит 1',
                taskName: 'Работать',
                taskDescription: 'Работать с 9 до 7',
                isNotReaded: true,
                dataReceive: '21.05 10:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Иванов',
                projectName: 'Аудит 1',
                taskName: 'Не работать',
                taskDescription: 'Больше отдыхать',
                isNotReaded: false,
                dataReceive: '21.05 10:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Петр Петров',
                projectName: 'Аудит 1',
                taskName: 'Закупка',
                taskDescription: 'Согласовать закупку',
                isNotReaded: false,
                dataReceive: '21.05 10:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Лариса Журавская',
                projectName: 'Аудит 1',
                taskName: 'Отчёт',
                taskDescription: 'Согласовать отчёт',
                isNotReaded: false,
                dataReceive: '21.05 10:00'
            }
        ]
    },
    {
        day: 'Раньше',
        tasksList: [
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: true,
                dataReceive: '19.05 10:00'
            },
            {
                userAvatarUrl: '',
                userName: 'Иван Фоменко',
                projectName: 'Аудит 1',
                taskName: 'Название',
                taskDescription: 'Описание поручения',
                isNotReaded: false,
                dataReceive: '10.05 10:00'
            }
        ]
    }
];

export { 
    mockTasksListData, 
    mockTasksFromMeListData, 
    mockTasksDraftListData, 
    mockTasksCompletedListData,
    mockTasksCompletedFromMeListData,
    mockTasksNewListData };