import TasksItem from "../tasksItem";
import { Button } from '../common/button';
import "./tasksList.scss";

const TasksList = ({ tasksListData, isCompleted=false, isNew=false }) => {
    return (
        <div className="tasks-list">
            <div className="tasks-list__filters">
                <div className="tasks-list__filters-wrap">
                    <div className="tasks-list__filters-checkbox">
                        <input type="checkbox" id="tasks-select-all" />
                        <label htmlFor="tasks-select-all">Выделить все</label>
                    </div>
                    <div className="tasks-list__filters-radio-list">
                        <div className="tasks-list__filters-radio">
                            <input type="radio" id="tasks-radio-basic" name="tasks-radios" defaultChecked/>
                            <label htmlFor="tasks-radio-basic">Основные</label>
                        </div>
                        <div className="tasks-list__filters-radio">
                            <input type="radio" id="tasks-radio-all" name="tasks-radios"/>
                            <label htmlFor="tasks-radio-all">Все</label>
                        </div>
                    </div>
                    
                </div>
                <Button 
                    type="button"
                    classes="btn-text btn-sm"
                    title="Фильтр"
                    leftIcon="icon-filter"
                />
            </div>
            <div className={`tasks-list__header ${isCompleted ? "_completed" : ""}  ${isNew ? "_new" : ""}`}>
                <div></div>
                <div className="tasks-list__header-item">От кого</div>
                <div className="tasks-list__header-item">Проект</div>
                <div className="tasks-list__header-item">Поручение</div>
                { !isNew && !isCompleted && (
                    <>
                        <div className="tasks-list__header-item">Статус</div>
                        <div className="tasks-list__header-item">Срочность</div>
                    </>
                ) }
                
                <div className="tasks-list__header-item">
                    <Button 
                        type="button"
                        classes="btn-text btn-sm"
                        title="Получено"
                        rightIcon="icon-chevron-down"
                    />
                </div>
                {
                    !isNew && (
                        isCompleted ? (
                            <div className="tasks-list__header-item">Дата выполнения</div> 
                        ):(
                            <div className="tasks-list__header-item">Крайний срок</div>
                        )
                    )
                }
            </div>
            {
                tasksListData.map((sublist) => (
                    <div key={sublist.day} className="tasks-list__table-section">
                        {(sublist.day !== 'Сегодня') && <div className="tasks-list__day">{sublist.day}</div>}
                        {
                            sublist.tasksList.map((item, index) => (
                                <TasksItem 
                                    key={index} 
                                    userAvatarUrl={item.userAvatarUrl}
                                    userName={item.userName}
                                    projectName={item.projectName}
                                    taskName={item.taskName}
                                    taskDescription={item.taskDescription}
                                    status={item.status}
                                    stringency={item.stringency}
                                    isNotReaded={item.isNotReaded}
                                    dataReceive={item.dataReceive}
                                    dataDeadline={item.dataDeadline}
                                    dataComplete={item.dataComplete}
                                    isCompleted={isCompleted}
                                    isNew={isNew} />
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default TasksList;