import "./tasksMenu.scss";

const TasksMenu = ({ menuList: { title, radioName, menuItems }, getTasksList }) => {
    return (
        <div className="tasks-menu">
            <h2 className="tasks-menu__title">{ title }</h2>
            <div className="tasks-menu__list">
                {
                    menuItems.map(({ id, tag, title, iconName, count, isChecked }, index ) => (
                        <div key={id} className="tasks-menu__item">
                            <input 
                                type="radio" 
                                id={id} 
                                name={radioName} 
                                value={index} 
                                defaultChecked={isChecked}
                                onClick={() => getTasksList(tag)}
                            />
                            <label htmlFor={id} className="tasks-menu__item-label">
                                <span className={`icon ${iconName}`} aria-hidden="true"></span>
                                {title}
                                {
                                    !!count && <span className="tasks-menu__item-count">{count}</span>
                                }
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TasksMenu;