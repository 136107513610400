import TeamItem from '../teamItem';
import './teamList.scss';

const TeamList = ({ title, list }) => {
    return (
        <section>
            <h2 className='section-title'>{title}</h2>
            <ul className='team-list'>
                {
                    list.map(({ avatarUrl, name, jobPosition }, index) => (
                        <TeamItem 
                            key={index}
                            avatarUrl={avatarUrl}
                            name={name}
                            jobPosition={jobPosition}
                        />
                    ))
                }
            </ul>
        </section>
    )
}

export default TeamList;