// src/pages/resourcesPage.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResourcesGant from "../components/resourcesGant";
import AuditorList from "../components/auditorList";
import { fetchUsers } from "../store/userSlice";
import { fetchAuditsByYear, addCuratorToAuditThunk, removeCuratorFromAuditThunk, removeAuditorFromAuditThunk } from "../store/auditSlice";

const ResourcesPage = () => {
  const dispatch = useDispatch();
  const transformedUsers = useSelector((state) => state.users.transformedUsers);
  const currentYear = new Date().getFullYear();
  const transformedData = useSelector((state) => state.audits.transformedData);
  const resourcesData = {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    data: transformedData,
  };

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchAuditsByYear(currentYear));
  }, [dispatch, currentYear]);

  const handleRemoveAuditor = (auditId, auditorId) => {
    dispatch(removeAuditorFromAuditThunk({ auditId, auditorId })).then(() => {
      // Force update transformedData to trigger re-render
      dispatch(fetchAuditsByYear(currentYear));
    });
  };

  const handleRemoveCurator = (auditId) => {
    dispatch(removeCuratorFromAuditThunk({ auditId })).then(() => {
      // Force update transformedData to trigg
    });
  };

  const handleAddCurator = (auditId, auditor) => {
    dispatch(addCuratorToAuditThunk({ auditId, auditor })).then(() => {
      // Force update transformedData to trigger
    });
  };

  return (
    <div className="d-flex spaces-x-80">
      <ResourcesGant
        title="Планирование ресурсов"
        resourcesData={resourcesData}
        onRemoveAuditor={handleRemoveAuditor}
        onRemoveCurator={handleRemoveCurator}
        onAddCurator={handleAddCurator}
      />
      <AuditorList title="Список аудиторов" auditorList={transformedUsers} />
    </div>
  );
};

export default ResourcesPage;
